import Head from "next/head";
import Script from "next/script";
import { useEffect,} from "react";
import { GA_TRACKING_ID } from "../../lib/gtag";
import GoogleTagManager from '../GTM/GoogleTagManager.js'
import GoogleTagManager_Body from '../GTM/GoogleTagManager_Body.js'


export default function Headcom(props) {
  useEffect(() => {});

  return (
    <div>
      <Head>
        <title>{props.title}</title>
        <meta name="description" content={props.s_dis} />
        <link rel="canonical" href={props.path} />
        <meta property="og:title" content={props.title} key={props.title} />
        <meta property="og:description" content={props.s_dis} />
        <meta property="og:url" content={props.path} />
        <meta property="og:image" content={props.thumb} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={props.thumb} />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <meta property="og:type" content="game" />
        <meta property="og:site_name" content={props.path} />
        <meta property="sg:type" content="portal" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="apple-mobile-web-app-title" content={props.path} />
        <meta name="application-name" content={props.path} />
        <meta name="turbolinks-cache-control" content="no-cache" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content={props.path} />
        <meta name="HandheldFriendly" content="true" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="yandex-verification" content="cec8a28d73052690" />
        <meta name="theme-color" content="#50136c" />
        <link rel="icon" type="image/svg" sizes="16x16" href={props.logo_16} />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="icon"
          type="image/svg"
          sizes="192x192"
          href={props.logo_192}
        />
        <link rel="apple-touch-icon" href={props.logo_512} />
        <meta name="msapplication-TileImage" content={props.logo_270} />
     
{/* <script type="text/javascript" src="/hotjar.js"></script> */}
      
      </Head>

      <Script
      defer
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} // Use your Tracking ID here (e.g., G-XXXXXXXXXX)
      />
      <Script id="google-analytics" strategy="afterInteractive" defer>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}'); // Use your Tracking ID here
        `}
      </Script>
      
      <GoogleTagManager/>
      <GoogleTagManager_Body/>

      {/* <Script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2622226100196993" crossOrigin="anonymous" strategy="afterInteractive" /> */}
      {/* <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} strategy="afterInteractive" /> */}
      {/* <Script async src={"https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"} /> */}
      {/* <Script async src={"https://s3-us-west-2.amazonaws.com/s.cdpn.io/130527/h5ab-snow-flurry.js"} /> */}
    

{/* <Script async strategy="afterInteractive" 
        dangerouslySetInnerHTML={{
          __html: `!function(e,t)
          {
            ((a = e.createElement("script")),
            (m = e.getElementsByTagName("script")[0]),
            (a.async = 1),
            (a.src = t),
            m.parentNode.insertBefore(a, m))
          }
          (document,"https://universal.wgplayer.com/tag/?lh="+window.location.hostname+"&wp="+window.location.pathname+"&ws="+window.location.search); `,
        }}/> */}


        {/* Global site tag (gtag.js) - Google Analytics */}
      

      <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2622226100196993"
          crossOrigin="anonymous"
          data-overlays="bottom"
          
          // strategy="lazyOnload" 
        ></script>




        {/* Global Site Tag (gtag.js) - Google Analytics */}
        {/* <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          strategy="lazyOnload" // lazyOnload, lazyOnload 
          /> */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        /> */}
      
    </div>
  );
}
